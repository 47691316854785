<template>
  <div class="px-5" id="page-grants">
    <h5 class="mb-6 ml-2">Föreningsbidrag</h5>
    
    <GrantTopCompanyView v-if="isTopCompany" />

    <GrantApplicationView v-else />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import GrantApplicationView from '@/view/pages/ml/grant/GrantApplicationView.vue';
import GrantTopCompanyView from '@/view/pages/ml/grant/GrantTopCompanyView.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grants',
  components: {
    GrantApplicationView,
    GrantTopCompanyView
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid'])
  },
  mounted() {
    this.isTopCompany = this.sid === this.currentCompanyId;
  },
  data() {
    return {
      isTopCompany: false
    };
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    },
    currentPeriodId(newValue, oldValue) {
      this.loadOnValidPeriodAndCompany();
    }
  },
  methods: {
    loadOnValidPeriodAndCompany() {
      if (this.currentCompanyId && this.currentPeriodId && this.currentPeriodId !== -1) {
        this.isTopCompany = this.sid === this.currentCompanyId;
      }
    },
  
  }
};
</script>
