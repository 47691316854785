<template>
    <b-card>
        <GrantCompanySettingsTable
          v-if="systemSettings"
          class="mt-8"
          :rows="grantSettingsRows"
          :system="systemSettings"
          @onSettingsChanged="onSettingsChanged"
          @onRowChanged="onRowChanged"
        />
    </b-card>

</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';

import GrantCompanySettingsTable from '@/view/pages/ml/grant/GrantCompanySettingsTable.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'GrantSettingsView',
  components: {
    Confirm,
    GrantCompanySettingsTable
  },
  mixins: [ toasts ],
  emits: ['onSettingsUpdated'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
  },
  mounted() {
    this.loadSettings();
  },
  data() {
    return {
      grantSettingsRows: [],
      systemSettings: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {

    save(company_id, grant) {
      axios
        .put(`/company/company_settings/${company_id}`, { company_id: company_id, grant: grant })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
          this.$emit('onSettingsUpdated', { company_id, grant });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera inställningar')
        });
    },

    onRowChanged(row) {
      this.save(row.company_id, { amount: row.amount });
    },

    loadSettings() {
      const loader = this.$loading.show();
      axios
        .get(`/system`)
        .then(res => {
          this.systemSettings = res.data;
        })
        .catch(err => {
          console.error(err);
        });

      axios
        .get(`/company/settings/grant`)
        .then(res => {
          this.grantSettingsRows = res.data;
          loader & loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta inställningar');
          loader & loader.hide();
        });
    },

    onSettingsChanged(default_grant_total) {
      axios
        .put(`/system`, { default_grant_total })
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera system');
        });
    },

  }
};
</script>
